import React, { useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import axios from 'axios';
import logo from './logo.png';
import { ToastContainer, toast } from 'react-toastify';

function App(){
  const [id, setId] = useState(0);
  const [domain, setDomain] = useState(null);
  const editorRef = useRef(null);

  const onLoad = () => {
    let params = new URLSearchParams(window.location.search);

    setId(params.get('id'));
    setDomain(params.get('domain'));

    axios.get(`https://${params.get('domain')}/utility/api/template/get?id=${params.get('id')}`).then((res) => {
      editorRef.current.editor.loadDesign(res.data);
    });
  };

  const exportHtml = () => {
    editorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      let params = {
        id: id,
        json: design,
        content: html,
      };
      
      axios.post(`https://${domain}/utility/api/template/save`, params).then((res) => {
        toast.success("Template successfully saved", {
          position: "top-center",
          theme: "light",
        });
      });
    });
  };

  return (
    <div className="App">
      <ToastContainer />
      <div className="content-wrapper">
        <div className="editor-header">
          <div className="editor-title">
            <img src={logo} alt="PlaybookAI Logo" />
            <h1>Template Builder</h1>
          </div>
          <div className="btn-container">
            <button className="button button1" onClick={exportHtml}>Save</button>
          </div>
        </div>

        <div className="editor-wrapper">
          <div className="overlay-box-middle"></div>
          <div className="overlay-box-right"></div>
          <EmailEditor
            ref={editorRef}
            onLoad={onLoad}
            options={{
              features: {
                textEditor: {
                  tables: true
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
